<template>
  <CabinetPage :preloader="preloader">

    <div class="mx-cabinet-page-header">
      <h4 class="mx-title">{{ $t('document.new_document') }}</h4>
<!--      <div class="mx-actions">-->
<!--        <router-link :to="{name: 'documents'}" class="btn btn-peepz-default">-->
<!--          <span>{{ $t('document.btn.abort') }}</span>-->
<!--        </router-link>-->
<!--      </div>-->
    </div>

    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="mx-form-header"></div>
        <form class="mx-form">
          <div class="mx-modal-form">

           <div class="row">
             <div class="col-md-6">
               <div class="mx-modal-form__element">
                 <label class="form-label">Name</label>
                 <input type="text" class="form-control"  v-model="form.name">
                 <div v-if="errors['name']" class="invalid-feedback">
                   {{ errors['name'][0] }}
                 </div>
               </div>
             </div>
             <div class="col-md-6">
               <div class="mx-modal-form__element">
                 <label class="form-label">Kategorie</label>
                 <b-form-select
                     v-model="form.category_id"
                     :options="categories"
                     class="form-control"
                     value-field="id"
                     text-field="name"
                 ></b-form-select>
               </div>
             </div>
             <div class="col-md-12">
               <div class="mb-3 mt-3">
                 <div class="mx-modal-prompt3" style="border: 1px solid #ced4da; border-radius: 0.25rem">
                   <input type="file" style="margin: 15px;" v-on:change="csvFileChange">
                   <span style="color: red; margin-bottom: 50px;" v-for="error in errors" v-if="error.status === 500">
                  {{ error.text }}
                </span>
                 </div>
               </div>
             </div>
           </div>
          </div>

          <div class="mx-form-buttons">
            <button :disabled="button_disabled || csvFile === null" @click="save" type="button" class="btn btn-peepz-success">
              {{ $t('company.person.csv.load') }}
            </button>
            <router-link :disabled="button_disabled" tag="button" :to="{name: 'documents'}" class="btn btn-peepz-default">
              <span>{{ $t('document.btn.abort') }}</span>
            </router-link>
          </div>
        </form>
      </div>
    </div>


  </CabinetPage>
</template>

<script>
import CabinetPage from "@/components/main/CabinetPage";
import axios from "axios";
import options from "../../../options";

export default {
  name: "DocumentShowView",
  components: {CabinetPage},
  data() {
    return {
      preloader: true,
      form: {
        name: '',
        employees: [],
        customers: [],
        teams: [],
        roles: [],
        for: 'employee',
        for_all: 0,
        category_id: 1
      },
      button_disabled: false,
      csvFile: null,
      errors: [],
      categories: [],
    }
  },
  created() {
    this.getDocuments()
  },
  methods: {
    getDocuments() {
      this.$store.dispatch('documentCategories').then(() => {
        this.categories = this.$store.getters.document_categories
        this.preloader = false
      })
    },
    csvFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      this.csvFile = files[0]
      this.errors = []


      if (this.csvFile !== null) {
        this.$store.dispatch('getContactPersons').then(() => {
          this.contact_persons = this.$store.getters.contact_persons;
          this.form.employees = this.contact_persons.map(p => {
            return p.employee_uid
          })
        })
      }

    },
    save() {
      this.button_disabled = true;

      let formData = new FormData()
      formData.append('file', this.csvFile)
      formData.set('name', this.form.name)
      formData.set('for', this.form.for)
      formData.set('for_all', this.form.for_all)
      formData.set('employees', this.form.employees)
      formData.set('customers', this.form.customers)
      formData.set('teams', this.form.teams)
      formData.set('roles', this.form.roles)
      formData.set('created_customer_id', this.$store.getters.user.uid)
      formData.set('category_id', this.form.category_id)

      axios.post(`${options.document_url_api}/documents`, formData, {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        this.$router.replace({
          name: 'documents'
        })
      }).catch(error => {
        this.button_disabled = false;
        this.csvFile = null
        this.errors = []
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data;
            break;
          case 500:
            this.errors.push({'status': 500, 'text': 'Server error'})
            break;
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
